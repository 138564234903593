<template>
  <div class="container-register">
    <div class="row">
      <div class="col-md-6">
        <img
          src="../../assets/imgs/login.jpg"
          class="img-fluid"
          alt=""
          width="600"
          height="auto"
        />
      </div>
      <div class="col-md-6" style="background: #fbfbfb">
        <div class="form">
          <div class="row mb-4">
            <div class="col-md-12">
              <h3 class="register-heading">
                {{ mode === "signin" ? "Login" : "register new agent" }}
              </h3>
              <hr />

              <p v-if="mode == 'signup'">
                Put your valid information and wait for confirmation
              </p>
              <p v-if="mode === 'signin'">fill your autherization info to proceed</p>
            </div>
          </div>
          <!-------------------------------------------------------------->
          <!-------------------------------------------------------------->
          <!-------------------------------------------------------------->
          <!-------------------------------------------------------------->

          <div class="row g-2">
            <div :class="mode === 'signin' ? 'col-md-12' : 'col-md-6'">
              <div class="form-group">
                <label>Email</label>
                <input
                  class="form-control"
                  type="email"
                  v-model="authData.email"
                  placeholder="email"
                  required
                />
                <p class="error-msg" v-for="err in emailErrors" :key="err">
                  {{ err }}
                </p>
              </div>
            </div>
            <div v-if="mode == 'signup'" class="col-md-6">
              <div class="form-group">
                <label>username</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="authData.username"
                  placeholder="username"
                  required
                />
                <p class="error-msg" v-for="err in usernameErrors" :key="err">
                  {{ err }}
                </p>
              </div>
            </div>
            <div v-if="mode == 'signup'" class="col-md-6">
              <div class="form-group">
                <label>Phone</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="authData.phone"
                  placeholder="phone"
                  required
                />
                <p class="error-msg" v-for="err in phoneErrors" :key="err">{{ err }}</p>
              </div>
            </div>
            <div v-if="mode == 'signup'" class="col-md-6">
              <div class="form-group">
                <!-------location-fix--------->
                <label>Location</label>
                <select
                  class="form-control"
                  @change="addLocation"
                  :value="authData.location[authData.location.length - 1]"
                >
                  <option
                    v-for="location in locations"
                    :key="location._id"
                    :value="location.name"
                  >
                    {{ location.name }}
                  </option>
                </select>
                <p class="error-msg" v-for="err in locationErrors" :key="err">
                  {{ err }}
                </p>
                <div class="chips">
                  <div v-for="(loc, i) in authData.location" :key="i" class="chip">
                    {{ loc }}
                    <i @click="removeLocation(loc)" class="close fas fa-times"></i>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="mode == 'signup'" class="col-md-6">
              <div class="form-group">
                <label>Role & position</label>
                <select class="form-control" v-model="authData.role" :value="roles[0]">
                  <option v-for="(role, key) in roles" :key="key" :value="key">
                    {{ roles[key] }}
                  </option>
                </select>
              </div>
            </div>
            <div :class="mode === 'signin' ? 'col-md-12' : 'col-md-6'">
              <div class="form-group">
                <label>Password</label>
                <input
                  type="password"
                  class="form-control"
                  v-model="authData.password"
                  placeholder="Password"
                  required
                />
                <p class="error-msg" v-for="err in passwordErrors" :key="err">
                  {{ err }}
                </p>
              </div>
            </div>
            <div v-if="mode == 'signup'" class="col-md-6">
              <div class="form-group">
                <label>Password confirmation</label>
                <input
                  type="password"
                  class="form-control"
                  v-model="authData.confirm"
                  placeholder="Password confirmation"
                  required
                />
                <p class="error-msg" v-for="err in confirmErrors" :key="err">{{ err }}</p>
              </div>
            </div>
          </div>
          <hr />
          <!-------------------------------------------------------------->
          <!-------------------------------------------------------------->
          <!-------------------------------------------------------------->
          <!-------------------------------------------------------------->
          <!-------------------------------------------------------------->
          <div class="row justify-content-center">
            <div class="col-4 center">
              <loading v-if="authLoading || loading" />
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-6">
              <button
                :disabled="!isActive"
                class="btn btn-primary btn-block w-100"
                style="text-center"
                @click.prevent="
                  () => {
                    submitted = true;
                    mode === 'signin' ? signin() : signup();
                  }
                "
              >
                {{ mode === "signin" ? "Signin" : "Signup" }}
              </button>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 center">
              <button class="btn btn-link btn-block" @click="switchMode">
                {{
                  mode === "signin"
                    ? "You don't have an account! register here"
                    : "Login if you already signed"
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  created() {
    this.mode = this.$route.params.mode;
    if (this.$route.params.id) this.mode = "update";
  },
  components: {},
  data() {
    return {
      loading: false,
      authLoading: false,
      submitted: false,
      changePassword: false,

      mode: "signin",
      pageToGo: "",

      authData: {
        email: "",
        username: "",
        phone: "",
        /////// location-fix /////////////
        location: [],
        role: 0,
        password: "",
      },
    };
  },
  watch: {
    // mode(val) {},
    ////////
  },
  computed: {
    emailErrors() {
      const errors = [];
      let ok = false;
      if (this.authData.email != "") ok = true;
      if (!ok && !this.submitted) return [];
      let regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!this.authData.email.toLowerCase().match(regExp))
        errors.push("Email is not valid");
      return errors;
    },
    usernameErrors() {
      const errors = [];
      let ok = false;
      if (this.authData.username != "") ok = true;
      if (!ok && !this.submitted) return [];
      if (this.authData.username.length < 6) errors.push("Username is short");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      let ok = false;
      if (this.authData.phone != "") ok = true;
      if (!ok && !this.submitted) return [];
      var regExp = /[a-z]/i;
      if (this.authData.phone.length < 11) errors.push("Phone is short");
      if (regExp.test(this.authData.phone)) errors.push("Phone is not valid");
      return errors;
    },
    locationErrors() {
      const errors = [];
      let ok = false;
      /////// location-fix /////////////
      if (this.authData.location.length > 0) ok = true;
      if (!ok && !this.submitted) return [];
      if (this.authData.location.length == 0) errors.push("location is requied");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      let ok = false;
      if (this.authData.password != "") ok = true;
      if (!ok && !this.submitted) return [];
      if (this.authData.password.length < 6) errors.push("password is short");
      return errors;
    },
    confirmErrors() {
      const errors = [];
      let ok = false;
      if (this.authData.confirm != "") ok = true;
      if (!ok && !this.submitted) return [];
      if (this.authData.confirm !== this.authData.password)
        errors.push("Password doesn't match");
      return errors;
    },
    /////////////////////////////////////////////////
    roles() {
      return this.$store.getters.roles;
    },
    locations() {
      return this.$store.getters.locations;
    },
    isActive() {
      let ready = true;
      if (this.mode == "signin") {
        if (this.emailErrors.length > 0 || this.passwordErrors.length > 0) ready = false;
      } else if (this.mode == "signup") {
        if (
          this.emailErrors.length > 0 ||
          this.phoneErrors.length > 0 ||
          this.usernameErrors.length > 0 ||
          this.passwordErrors.length > 0 ||
          this.confirmErrors.length > 0 ||
          this.locationErrors.length > 0
        )
          ready = false;
      }
      return ready;
    },
  },
  methods: {
    signin() {
      if (!this.isActive) return;
      this.loading = true;
      axios
        .post("/signin", {
          email: this.authData.email,
          password: this.authData.password,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.err) {
            this.$store.dispatch("writeMessage", res.data.err);
          } else {
            //////////////////////MESSAGE///////////////////////////////
            this.setLocal(res.data.auth);
            this.$store.dispatch("getSystemData");
            this.$router.push("/");
          }
        });
    },

    signup() {
      if (!this.isActive) return;
      this.loading = true;
      axios.post("/signup", this.authData).then((res) => {
        this.loading = false;
        if (res.data.err) {
          this.$store.dispatch("writeMessage", res.data.err);
        } else {
          this.$router.push("/");
          this.$store.dispatch("writeMessage", "بأنتظار التأكيد");
        }
      });
    },

    switchMode() {
      this.mode === "signin" ? (this.mode = "signup") : (this.mode = "signin");
    },

    switchChangePassword() {
      this.changePassword = !this.changePassword;
    },

    update() {
      axios.post(`/updateUser/${this.userId}`, this.authData).then((res) => {
        this.loading = false;
        if (res.data.updated) {
          this.$store.dispatch("writemessage", "تم تحديث بيانات الطالب بنجاح");
          if (this.role == 0) {
            this.$router.replace("/");
          } else if (this.role == 1) {
            this.$router.back();
          }
        } else {
          this.$store.dispatch("writemessage", "تأكد من صحة كلمة السر");
        }
      });
    },
    setLocal(auth) {
      this.$store.dispatch("afterSignin", auth);
    },
    /////// location-fix /////////////
    addLocation(e) {
      const location = this.authData.location;
      if (!location.includes(e.target.value)) location.push(e.target.value);
    },
    removeLocation(loc) {
      const index = this.authData.location.findIndex((location) => location == loc);
      this.authData.location.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.register-heading {
  h3 {
    font-size: 2rem;
    @media (max-width: 500px) {
      font-size: 1rem;
    }
  }
}
.container-register {
  direction: ltr;
  text-align: left;
  padding: 40px;
  background: rgba(255, 255, 255, 0.898);
  @media (max-width: 500px) {
    padding: 60px 12px;
  }
}
.form-group {
  .error-msg {
    color: #dc3545;
    margin-top: 5px;
    &::before {
      content: "*";
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 14px;
      font-size: inherit;
      text-rendering: auto;
      -moz-osx-font-smoothing: grayscale;
      margin-left: 5px;
    }
  }
}
</style>
